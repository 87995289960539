@import url('./fonts.css');

/* Global Css */
body, body > * { box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html, body, div, span, input, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td {font-family: 'Poppins', sans-serif; vertical-align: baseline; box-sizing: border-box; }

iframe {
    display: none;
}

:root {
    --primary-navy: #092C4C;
    --white: #fff;
    --marin-green: #378D9A;
    --light-marin-green: #378d9a1a;
    --light-gray: #7E7E7E;
    --light-gray-2: #E7E7E7;
    --dark-gray: #49454F;
    --primary-font-family: 'Poppins', sans-serif !important;
    --secondary-font-family: 'Inter', sans-serif !important;
    --roboto-font: 'Roboto', sans-serif !important;
}

* {
    scrollbar-width: thin;
}

/* ::-webkit-scrollbar-track{
	background-color: var(--white);
}
::-webkit-scrollbar{
	width: 6px;
	background-color: var(--white);
}
::-webkit-scrollbar-thumb{
	background-color: #D0D0D0;
} */
body > *{ scrollbar-width: thin; scrollbar-color: #9FD4DC transparent; }
::-webkit-scrollbar { width: 6px; height: 6px; }
::-webkit-scrollbar-track { background-color: transparent; }
::-webkit-scrollbar-thumb { background: #9FD4DC; }
  

body {font-family: 'Poppins', sans-serif !important;}
.d-flex { display: flex;}
.justify-content-between { justify-content: space-between;}
.justify-content-center { justify-content: center;}
.align-items-center{align-items: center;}
.w-100{width: 100%;}
.h-100{height: 100%;}
.flex-grow-1{flex-grow: 1;}
.position-relative{position: relative;}
input:focus-visible{ outline-color: var(--marin-green); }

/* Loader */
.fa-scd-loader{ position: absolute; width: 100%; height: 100%; top: 0; left: 0; display: flex; align-items: center; justify-content: center; z-index: 999; background-color: #e5e5e5;}
.fa-scd-loader .loader-img img{max-width: 250px; mix-blend-mode: multiply;}
.fa-scd-loader .loader-img{position: relative;}
.loader-inner {display: block; --height-of-loader: 4px; --loader-color: var(--marin-green); width: 100%; height: var(--height-of-loader); border-radius: 30px; background-color: rgba(0,0,0,0.2); position: absolute; bottom: 7px; left: 0;}
.loader-inner::before { content: ""; position: absolute; background: var(--loader-color); top: 0; left: 0; width: 0%; height: 100%; border-radius: 30px; animation: moving 1s ease-in-out infinite;}
@keyframes moving {
    50% {width: 100%;}
    100% {width: 0; right: 0; left: unset;}
}
/* Slider Range Circle  */
.MuiSlider-thumb.css-frv3qg-MuiSlider-thumb.Mui-active{box-shadow: 0px 0px 0px 8px rgba(55, 141, 154, 0.16);}

/* Header Start */
header.fa-header{background-color: var(--white); box-shadow: none;}
header.fa-header .fa-header-container{max-width: 100%; border-bottom: 1px solid #EEE; padding: 0 20px; background-color: var(--white);}
header.fa-header .fa-header-wrapper{min-height: 64px;}
header.fa-header .fa-logo img{max-width: 320px;}
header.fa-header .nav-links{padding: 8px 10px; color: var(--primary-navy); text-decoration: none; font-family: 'Poppins', sans-serif; font-size: 16px; font-weight: 500; line-height: 1; letter-spacing: 0.1px; cursor: pointer;}
header.fa-header .nav-links:hover{color: var(--marin-green);}
header.fa-header .navbar-user-sec button:hover{background-color: transparent;}
header.fa-header .navbar-user-sec button svg{width: 38px; height: 38px;}

/* Sidebar Start */
.fa-toolbar-inner{display: flex; flex-direction: column;}
.fa-body-ctn {height: calc(100vh - 64.8px); width: 100%;}
.fa-body-ctn .fa-sidebar{width: 340px;}
.fa-sidebar .sidebar-toggle-btn{position: absolute; top: 17px; left: -1px; width: 20px; height: 30px; border: none; background-color: #fff; box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.18); transition: all 0.1s linear; transform: rotateY(180deg); border-radius: 4px 0px 0px 4px;}
.fa-sidebar .sidebar-toggle-btn.active{transform: rotateY(0deg); border-radius: 0px 4px 4px 0px;}
.fa-sidebar .fa-sidebar-inner{height: calc(100vh - 65px); width: 100%; flex-shrink: 0;}
.fa-sidebar .fa-sidebar-inner.collapse{width: 18px;}
.fa-sidebar .fa-sidebar-inner .MuiPaper-elevation{position: initial; overflow: visible;}
.fa-sidebar .fa-sidebar-inner .MuiDrawer-paper{width: 100%; background-color: #fff; box-shadow: 0px 10px 60px 0px rgba(226, 236, 249, 0.80); box-sizing: border-box;}
.fa-sidebar .fa-sidebar-top{padding: 18px 20px; position: relative; width: 100%; border-bottom: 1px solid #F2F2F2;}
.fa-sidebar .fa-sidebar-top .search-field-wrapper{ width: 100%; position: absolute; background-color: var(--white); z-index: -1; top: 0; left: 0; display: none; transition: display 0.3s linear;}
.fa-sidebar .fa-sidebar-top .search-field-wrapper.active{width: 100%; z-index: 1; display: flex; justify-content: space-between; height: 40px;}
.fa-sidebar .fa-sidebar-top .sidebar-tabs-btn{color: var(--light-gray); font-size: 16px; font-weight: 500; line-height: 21px; letter-spacing: -0.16px; border: none; padding: 8px 20px; border-radius: 10px; background-color: var(--white); min-height: 38px; font-family: var(--primary-font-family);}
.fa-sidebar .fa-sidebar-top .sidebar-tabs-container{min-height: initial;}
.fa-sidebar .fa-sidebar-top .sidebar-tabs-container .MuiTabs-flexContainer{gap: 12px;}
.fa-sidebar .fa-sidebar-top .sidebar-tabs-btn.Mui-selected{background-color: var(--marin-green); color: var(--white);}
.fa-sidebar .fa-sidebar-top .search-field{border: 1px solid var(--light-gray-2); background: #F9FBFF; padding: 11px 14px 11px 40px; color: var(--light-gray); font-family: var(--primary-font-family); font-size: 14px; font-weight: 400; line-height: 18px; letter-spacing: -0.16px; width: calc(100% - 40px); min-width: 40px; border-radius: 10px;}
.fa-sidebar .fa-sidebar-top .search-close-btn{min-width: 30px; padding: 0; transform: rotateZ(45deg);}
.fa-sidebar .fa-sidebar-top .search-close-btn:hover{background-color: transparent;}
.fa-sidebar .fa-sidebar-top .search-icon{position: absolute; transform: translate(10px, 50%); display: flex; align-items: center;}
.fa-sidebar .fa-sidebar-top .search-icon-btn{padding: 0; min-width: initial; width: 30px; height: 30px; border: 1px solid var(--light-gray-2); border-radius: 50%;}
.fa-sidebar .fa-sidebar-top .search-icon-btn:hover {background-color: rgba(0, 0, 0, 0.04)}
.fa-sidebar .fa-sidebar-top .add-list-btn{width: 30px; height: 30px; border-radius: 50%; border: 1px solid var(--light-gray-2);}
.fa-sidebar .fa-sidebar-top .add-list-btn svg{width: 16px;}
/* .fa-sidebar .sidebar-ul {display: flex; flex-direction: column; align-items: center;} */
/** No views placeholder text */
.fa-sidebar .sidebar-ul .sidebar-list-wrap p{text-align: center;}
/** Saved view list skeleton */
.fa-sidebar .sidebar-ul .skel-saved-view-list {display: inline-block; text-align: center; width: 100%;}
.fa-sidebar .fa-sidebar-inner .list-item-wrapper{padding: 12px 25px; border-bottom: 1px solid #F2F2F2;}
.fa-sidebar .fa-sidebar-inner .sidebar-li{width: 100%; position: initial;}
.fa-sidebar .fa-sidebar-inner .sidebar-li.delete-load,
.fa-sidebar .fa-sidebar-inner .sidebar-li.duplicate-load{position: relative;}
.fa-sidebar .fa-sidebar-inner .sidebar-li.delete-load::before,
.fa-sidebar .fa-sidebar-inner .sidebar-li.duplicate-load::before{content: ''; position: absolute; width: 100%; height: 100%; top: 0; left: 0; background-color: #378d9ac7; background-image: url(../image/Bin.gif); background-repeat: no-repeat; background-position: center; background-size: 40px; z-index: 9;} 
/* .fa-sidebar .fa-sidebar-inner .sidebar-li.duplicate-load::before{background-image: url(../image/Gear-Loader.gif);} */
.fa-sidebar .fa-sidebar-inner .list-item-wrapper:hover{background-color: var(--light-marin-green);}
.fa-sidebar .fa-sidebar-inner .list-item-wrapper.active{background-color: var(--marin-green); border-color: var(--marin-green);}
.fa-sidebar .fa-sidebar-inner .list-item-wrapper .list-icon{min-width: 24px; width: 24px; height: 24px; margin-right: 10px;}
.fa-sidebar .fa-sidebar-inner .list-item-wrapper .list-icon svg{width: 100%; height: 100%;}
.fa-sidebar .fa-sidebar-inner .list-item-wrapper.active svg path{fill: var(--white);}
.fa-sidebar .fa-sidebar-inner .list-item-wrapper .list-text-wrapper{color: var(--primary-navy); font-size: 14px; font-weight: 400; line-height: 20px; align-items: center; column-gap: 10px;}
.fa-sidebar .fa-sidebar-inner .list-item-wrapper .list-text-wrapper .view-more{height: 24px; position: relative; align-items: center; display: flex;}
.fa-sidebar .fa-sidebar-inner .list-item-wrapper .list-text-wrapper .view-more .btn-close-box{display: flex; align-items: center;}
.fa-sidebar .fa-sidebar-inner .list-item-wrapper .view-more .view-more-btn{background-color: transparent; border: none; padding: 4px;  cursor: pointer; z-index: 99;}
.fa-sidebar .fa-sidebar-inner .list-item-wrapper.active .list-text-wrapper{color: var(--white);}
.fa-sidebar .fa-sidebar-inner .list-item-wrapper .list-text-wrapper .list-text{background-color: transparent; border: none; color: var(--primary-navy); font-size: 16px; font-weight: 400; line-height: 20px; padding: 6px 10px; border-radius: 4px; display: block; max-width: initial; min-width: initial; width: 120px; flex-grow: 1;}
.fa-sidebar .fa-sidebar-inner .list-item-wrapper .list-text-wrapper .list-text.visible{background-color: var(--light-marin-green);}
.fa-sidebar .fa-sidebar-inner .list-item-wrapper.active .list-text-wrapper .list-text{color: var(--white);}
.fa-sidebar .fa-sidebar-inner .list-item-wrapper .list-text-wrapper .view-more-input{display: flex; gap: 12px; align-items: center;}
.fa-sidebar .sidebar-ul{flex-grow: 1; position: initial;}
.fa-sidebar .sidebar-ul ul{list-style: none; padding: 0; margin:0;}
.fa-sidebar .fa-customise-view{padding: 12px 0; box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.09) inset; background: var(--white); width: 100%;}
.fa-sidebar .fa-customise-view .customise-view-title{padding: 0 20px;}
.fa-sidebar .fa-customise-view .customise-view-title h4{color: #8B8B8B; font-size: 16px; font-weight: 500; line-height: 24px; letter-spacing: -0.16px; margin-bottom: 12px; margin-top: 0;}
.fa-sidebar .fa-customise-view .customise-view-ul > li{padding: 14px 20px 14px 10px; position: relative;}
.fa-sidebar .fa-customise-view .customise-view-ul input{color: var(--primary-navy); font-size: 16px; font-weight: 400; line-height: 20px; border: none; display: inline-block; max-width: 160px; background-color: transparent; padding: 11px 10px; font-family: var(--primary-font-family); letter-spacing: -0.16px; border-radius: 8px; border: 1px solid var(--marin-green);}
.fa-sidebar .fa-customise-view .customise-view-ul .btn-wrap{position: relative; flex-wrap: nowrap; display: flex; align-items: center; gap: 12px;}
.fa-sidebar .fa-customise-view .customise-view-ul .btn-wrap .btn-close-box{cursor: pointer;}
.fa-sidebar .fa-customise-view .customise-view-ul .btn-wrap .btn-popup,
.fa-sidebar .fa-sidebar-inner .view-more .view-more-popup{position: absolute; z-index: 9; border-radius: 10px; box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.30);}
.fa-sidebar .fa-customise-view .customise-view-ul .btn-wrap .btn-popup{left: 92%; top: -16px;}
.fa-sidebar .fa-customise-view .customise-view-ul .btn-wrap .popup-bg-wrap,
.fa-sidebar .fa-sidebar-inner .view-more-popup-bg{position: fixed; width: 100%; height: 100%; top: 0; left: 0; z-index: 8;}
.fa-sidebar .fa-sidebar-inner .view-more-popup{position: absolute; top: -24px; left: 22px; width: 170px; z-index: 9;}
.fa-sidebar .fa-customise-view .customise-view-ul .btn-wrap .btn-popup ul,
.fa-sidebar .fa-sidebar-inner .view-more-popup ul{list-style: none; border-radius: 10px; overflow: hidden; background-color: var(--white);}
.fa-sidebar .fa-customise-view .customise-view-ul .btn-wrap .btn-popup ul::before,
.fa-sidebar .fa-sidebar-inner .view-more-popup ul::before{content: ''; position: absolute; top: -3px; left: -20px; z-index: -1; background-image: url('../svg/popup-pointer.svg'); width: 60px; height: 52px; }
.fa-sidebar .fa-customise-view .customise-view-ul .btn-wrap .btn-popup ul li,
.fa-sidebar .fa-sidebar-inner .view-more-popup ul li{padding: 10px 20px; color: var(--primary-navy); font-size: 16px; font-weight: 400; line-height: 24px; height: 48px; background-color: var(--white); background-size: contain; text-align: left;}
.fa-sidebar .fa-customise-view .customise-view-ul .btn-wrap .btn-popup ul li:hover{background: rgba(235, 244, 245, 0.50);}
.fa-sidebar .fa-customise-view .customise-view-ul .btn-wrap .btn-popup ul li:not(:last-child),
.fa-sidebar .fa-sidebar-inner .view-more-popup ul li:not(:last-child){border-bottom: 1px solid #F2F2F2;}
.fa-sidebar .fa-customise-view .customise-view-ul button,
.fa-sidebar .fa-sidebar-inner .list-item-wrapper .list-text-wrapper .save-title{border-radius: 10px; border: 1px solid rgba(55, 141, 154, 0.50); background: #378D9A; padding: 6px 20px; color: var(--white); font-size: 16px; font-weight: 500; line-height: 24px; min-width: 100px; cursor: pointer;}
.fa-sidebar .fa-customise-view .customise-view-ul button:disabled,
.fa-sidebar .fa-sidebar-inner .list-item-wrapper .list-text-wrapper .save-title:disabled{background-color: #378d9aad; border-color: #378d9aad;}
.fa-sidebar .fa-customise-view .customise-view-ul button.close-icon-btn{min-width: initial; background: no-repeat; border: none; padding: 0;}
.fa-sidebar .fa-customise-view .customise-view-ul button.close-icon-btn:disabled{opacity: 0.5;}
.fa-sidebar .fa-customise-view .customise-view-ul button.load_btn,
.fa-sidebar .fa-sidebar-inner .list-item-wrapper .list-text-wrapper .save-title.load_btn{position: relative; padding-left: 32px;}
.fa-sidebar .fa-customise-view .customise-view-ul button.load_btn::before{content: ''; position: absolute; top: 8px; left: 8px; width: 15px; height: 15px; border: 2px solid transparent; border-top-color: #fff; animation: spin 1000ms linear infinite; border-radius: 50%;}
.fa-sidebar .fa-sidebar-inner .list-item-wrapper .list-text-wrapper .save-title{margin-right: 0; padding: 6px 10px; line-height: 18px; min-width: 65px; border-radius: 6px;}
.fa-sidebar .fa-sidebar-inner .list-item-wrapper.active .list-text-wrapper .save-title{background-color: var(--white); color: var(--marin-green);}
.fa-sidebar .fa-customise-view .customise-view-ul > li:hover,
.fa-sidebar .fa-customise-view .customise-view-ul > li.active{background: rgba(55, 141, 154, 0.10);}
.fa-sidebar .fa-customise-view .customise-view-ul > li.active::before{content: ''; width: 7px; height: 100%; background-color: var(--marin-green); position: absolute; left: 0; top: 0;}
.fa-sidebar .fa-customise-view .customise-view-li-inner{display: flex; justify-content: space-between; align-items: center; width: 100%; flex-wrap: wrap;}
.fa-sidebar .fa-customise-view .customise-view-li-inner span{padding: 0 10px;}
/* .fa-sidebar .fa-sidebar-inner .list-item-wrapper .list-text-wrapper .save-title::before{content: ''; position: absolute; top: 8px; left: 8px; width: 15px; height: 15px; border: 2px solid transparent; border-top-color: #fff; animation: spin 1000ms linear infinite; border-radius: 50%;} */


@keyframes spin {
    0% {transform: rotate(0deg);}
    100% {transform: rotate(360deg);}
  } 

/* Mian Content Section Start */
.main-content{display: flex; flex-direction: column; height: 100%; width: calc(100% - 340px); overflow-x: scroll; transition: display 0.3s linear;}
.main-content.sidebar-collapse{width: 100%;}
/* .main-content.active{width: 100%;} */
.main-content .filter-bar{padding: 18px 24px; display: flex; align-items: center; gap: 16px; position: relative; background-color: var(--white);}
.main-content .filter-bar .filter-btn{padding: 10px 14px 10px 42px; border-radius: 10px; border: 1px solid #378D9A; background: var(--white); min-width: 160px; display: block; text-align: left; position: relative; color: var(--marin-green); font-family: var(--secondary-font-family); font-size: 14px; font-weight: 500; line-height: 20px; letter-spacing: 0.08px; text-transform: capitalize;}
.main-content .filter-bar .filter-btn.active{background-color: #378D9a; color: #fff;}
.main-content .filter-bar button.text-btn{background: #378D9A; color: #ffff; border-radius: 10px; padding: 6px 12px; height: 42px;}
.main-content .filter-bar .icon-btn{width: 38px; /* height: 38px */; min-width: 38px;}
.main-content .filter-bar .filter-btn.active .btn-icon.end{background-color: #fff; color: #378D9a;}
.main-content .filter-bar .filter-btn.active .btn-icon svg path{fill: #fff; stroke: #fff;}
.main-content .filter-bar .icon-btn svg{width: 100%; height: 100%;} 
.main-content .filter-bar .filter-btn.bar-btn-2{min-width: 192px;}
.main-content .filter-bar .filter-btn .btn-icon{position: absolute; top: 10px; left: 14px; width: 20px; height: 20px;}
.main-content .filter-bar .filter-btn .btn-icon svg{width: 100%; height: 100%;}
.main-content .filter-bar .filter-btn .btn-icon.end{left: initial; right: 16px; border-radius: 50%; border: 1px solid #378D9A; display: flex; align-items: center; justify-content: center;}
.main-content .filter-bar .filter-bar-search{position: relative;}
.main-content .filter-bar .filter-bar-search input{width: 100%; height: 100%; border-radius: 10px; border: 1px solid var(--light-gray-2);    background: #F9FBFF; padding: 12px 16px 12px 48px;}
.main-content .filter-bar .filter-bar-search span.search-icon{position: absolute; top: 12px; left: 16px;}
.main-content .filter-bar .filter-bar-search .err-wrap{position: absolute; top: 50%; right: 16px; transform: translateY(-50%);}
.main-content .filter-bar .filter-bar-search .err-wrap span{font-size: 14px; line-height: 1; color: #cd5656; position: relative; padding-left: 20px; display: block; margin-left: 84px;}
.main-content .filter-bar .filter-bar-search .err-wrap span.filterbar-search-error:before{content: ''; position: absolute; left: 0; top: 50%; width: 14px; height: 14px; transform: translateY(-50%); background-image: url(../svg/error-red.svg); background-size: contain; background-position: center;}

/* Table Start */
.fa-table-main {width: 100%; border-spacing: 0; border-top: 1px solid rgba(0, 0, 0, 0.12);}
.fa-table-main tbody tr{height: 78px;}
.fa-table-main tbody tr .fa-tbody-cell{font-size: 16px; line-height: 22px; display: -webkit-box;  -webkit-line-clamp: 3; -webkit-box-orient: vertical; overflow: hidden;}
.fa-table-main thead tr .fa-thead-cell{ line-height: 26px; display: -webkit-box;  -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden;}
.fa-table-main tbody tr:nth-child(odd){background-color: var(--light-marin-green) !important;}
.fa-table-main tbody tr:nth-child(n):hover,
.fa-table-main tbody tr:hover td:last-child,
.fa-table-main tbody tr:hover td:nth-last-child(2),
.fa-table-main tbody tr:hover td:nth-last-child(3),
.fa-table-main tbody tr:hover td:first-child{background-color: #378d9a !important;}
.fa-table-main tbody tr:nth-child(n):hover td,
.fa-table-main tbody tr:hover td:nth-last-child(3) p{color: #fff;}
.fa-table-main tbody tr:nth-child(n):hover td .tbl-checkbox svg,
.fa-table-main tbody tr:nth-child(n):hover td button svg path{fill: #fff;}
.fa-table-main tbody tr:nth-child(n) td .tbl-checkbox svg{transition: none;}
.fa-table-main tbody tr:nth-child(even){background-color: var(--white) !important;}
.fa-table-main thead th{padding: 10px 10px 10px;/*  border: 1px solid #eeeeee; */ position: relative;}
.fa-table-main tbody td{padding: 12px 14px; max-width: fit-content;}
.fa-table-main thead th span{color: #8B8B8B; font-family: var(--primary-font-family); font-size: 14px; font-weight: 500; line-height: 22px; letter-spacing: -0.16px; display: block; max-height: 48px;}
.fa-table-main tbody td p{color: var(--primary-navy); font-family: var(--primary-font-family); font-size: 16px; font-weight: 400; line-height: 30px;}
.fa-table-main tbody td p:empty{line-height: 0;}
.fa-table-main tbody td.td-img-wrap{width: 116px;}
.fa-table-main tbody td img{  max-width: 100%; height: auto; display: block; object-fit: cover; object-position: center; min-width: 88px;}
.fa-table-main tbody td .fa-table-img{border-radius: 8px; overflow: hidden; max-width: 88px; }
.fa-table-main tbody td.td-prop-title p{ white-space: nowrap; width: 150px; overflow: hidden; text-overflow: ellipsis;}
.fa-table-main tbody td.td-status span{ word-break: break-all; color: #063775; font-weight: 500; font-family: var(--primary-font-family); letter-spacing: -0.16px; font-size: 16px; line-height: 32px; padding: 0 16px 0 32px; display: block; border-radius: 16px; background: #CFE3FC; position: relative;}
.fa-table-main tbody td.td-status span::before{content: ''; position: absolute; top: 12px; left: 16px; width: 9px; height: 9px; border-radius: 50%; background-color: #063775;}
.fa-table-main tbody td span svg.MuiSvgIcon-root{font-size: 28px;}
.fa-table-main thead th span{width: fit-content;}
.fa-table-main tbody tr:nth-child(odd) td:first-child{background-color: #ebf3f5;}
.fa-table-main tbody td span{max-width: 150px; text-wrap: wrap; word-wrap: break-word; display: -webkit-box;  -webkit-line-clamp: 3; -webkit-box-orient: vertical; overflow: hidden;}
.fa-table-main tbody td.td-status .fa-data-status{display: flex;}
.grid-table .leads-table-header{font-weight: 600;}
.main-content .filter-content .leads-display{display: flex; flex-direction: column; flex: 1 1 100%; overflow-y: scroll;}
.main-content .filter-content .table-wrap{flex: 1 1 100%; overflow-y:scroll; overflow-x:scroll; display: flex; flex-direction: column;}
.main-content .filter-content .table-wrap .grid-table.fill-grid{block-size: 100%; flex: 1 1 100%;}
.main-content .filter-content .search-table-wrap{overflow-y:scroll; overflow-x: scroll; flex: 1 1; display: flex; flex-direction: column; contain: inline-size;}
.main-content .filter-content .search-table-wrap .grid-table.search-table {flex: 1 1;}

/* Filter Model Start */
.filter-modal .MuiModal-backdrop{background-color: transparent;}
.filter-modal .filter-modal-inner{width: 100%; max-width: 1200px; max-height: 740px; height: 80%; border-radius: 8px; border: 1px solid rgba(55, 141, 154, 0.50); background: #FCFCFF; -webkit-box-shadow: 0px 0px 16px 0px rgba(226, 236, 249, 0.80);box-shadow: 0px 0px 16px 0px rgba(226, 236, 249, 0.80); display: flex; transition: all 0.1s ease; padding: 0; overflow: hidden;}
.filter-modal.buybox-modal .filter-modal-inner{max-width: 327px; border: none; border-radius: 0;}
.filter-modal .filter-modal-inner.shortBy-modal{max-width: 800px; position: initial; transform: none;}
.filter-modal .filter-modal-inner.fullScreen { max-width: 97vw; transition: all 0.5s ease;  height: calc(100vh - 30px); max-height: inherit;}
.filter-modal .filter-modal-inner .fa-filter-modal-sidebar{width: 324px; display: flex; flex-direction: column; border-right: 1px solid #378d9a80;}
.filter-modal.buybox-modal .filter-modal-inner .fa-filter-modal-sidebar{border: none;}
.filter-modal.buybox-modal .filter-modal-inner .fa-filter-modal-sidebar{width: 100%; border: 1px solid #378D9A; border-radius: 8px; overflow: hidden;}
.filter-modal .filter-modal-inner .fa-filter-modal-main .short-by-ctn-list-wrapper{flex-grow: 1; padding-top: 80px; overflow: auto;}
.filter-modal .filter-modal-inner .fa-filter-modal-main .short-by-ctn-list-wrapper p.no_sorttext {padding: 8px 10px;margin: 0 20px;text-align: center;border: 1px solid var(--light-gray-2);border-radius: 8px;}
.filter-modal .filter-modal-inner .fa-filter-modal-main .short-by-ul{list-style: none; margin: 0;}
.filter-modal .filter-modal-inner .fa-filter-modal-main .short-by-ul li{display: flex; gap: 35px; padding: 0; width: fit-content; margin-bottom: 18px;}
.filter-modal .filter-modal-inner .fa-filter-modal-main .short-by-ul .short-by-li-inner{border-radius: 10px; border: 1px solid var(--light-gray-2); padding: 10px; gap: 14px; min-width: 350px; background-color: #FCFCFF; cursor: pointer;}
.filter-modal .filter-modal-inner .fa-filter-modal-main .short-by-ul button{padding: 0; min-width: initial;}
.filter-modal .filter-modal-inner .fa-filter-modal-main .short-by-ul p{margin: 0; flex-grow: 1;}
.filter-modal .filter-modal-inner .fa-filter-modal-main{width: calc(100% - 324px); display: flex; flex-direction: column;}
.filter-modal .fa-filter-modal-sidebar p{margin:0; color: var(--dark-gray); font-size: 16px; font-weight: 600; line-height: 24px; letter-spacing: 0.08px;}
.filter-modal .fa-filter-modal-sidebar .sibar-top .applied-filter{padding: 18px 26px; border-bottom: 1px solid #378d9a80;}
.filter-modal .fa-filter-modal-sidebar .sibar-top .applied-filter .start.btn-icon{height: 24px; margin-right: 12px;}
.filter-modal .fa-filter-modal-sidebar .sibar-top .applied-filter .end.btn-icon{height: 24px; width: 24px; border-radius: 50%; background-color: #378D9A; margin-left: auto; display: flex; justify-content: center; color: var(--white); font-size: 12px; font-weight: 500; font-family: var(--secondary-font-family); align-items: center;}
.filter-modal .fa-filter-modal-sidebar .sibar-top .filter-modal-search{padding: 15px 26px; border-bottom: 1px solid #378d9a80;}
.filter-modal .fa-filter-modal-sidebar .sibar-top .filter-modal-search-inner{position: relative;}
.filter-modal .fa-filter-modal-sidebar .sibar-top .filter-modal-search input{border-radius: 10px; border: 1px solid var(--light-gray-2); background: #F9FBFF; padding: 10px 18px 10px 50px; color: var(--light-gray); font-family: var(--primary-font-family); font-size: 16px; font-weight: 400;}
.filter-modal .fa-filter-modal-sidebar .sibar-top .filter-modal-search span{position: absolute; left: 18px; top: 50%; transform: translateY(-50%); display: block;}
.filter-modal .fa-filter-modal-sidebar .sibar-top .filter-modal-search span svg{display: block;}
.filter-modal .fa-filter-modal-sidebar .sidebar-body{flex-grow: 1; overflow: auto;}
.filter-modal .fa-filter-modal-sidebar .sidebar-body .fa-accordian-inner{height: 64px; min-height: initial; padding: 0px 26px; border-bottom: 1px solid #378d9a80;}
.filter-modal .fa-filter-modal-sidebar .sidebar-body div.fa-accordian {margin: 0; box-shadow: none;}
.filter-modal .fa-filter-modal-sidebar .sidebar-body .accordian-list{padding: 0;}
.filter-modal.shortby-modal{position: absolute; left: 0; top: 0; width: 100%; height: 100%; display: flex; align-items: center; justify-content: center;}
.filter-modal .filter-modal-inner .fa-filter-modal-sidebar .shortBy-sidebar-body ul{list-style: none; padding: 0; margin: 0;}
.filter-modal .fa-filter-modal-sidebar .sidebar-body .accordian-list .accordian-list-item,
.filter-modal .filter-modal-inner .fa-filter-modal-sidebar .shortBy-sidebar-body li{border-bottom: 1px solid #378d9a80; height: 64px; padding: 0 26px 0 40px;}
.filter-modal .filter-modal-inner .fa-filter-modal-sidebar .shortBy-sidebar-body li{display: flex; align-items: center;}
.filter-modal .fa-filter-modal-sidebar .sidebar-body .accordian-list .accordian-list-item label{flex-grow: 1; margin: 0;}
.filter-modal .filter-modal-inner .fa-filter-modal-sidebar .shortBy-sidebar-body{flex-grow: 1; overflow: auto;}
.filter-modal .fa-filter-modal-sidebar .sidebar-body .accordian-list .accordian-list-item span.MuiTypography-root{color: var(--dark-gray); font-family: var(--primary-font-family); font-size: 16px; font-weight: 400; line-height: 24px; letter-spacing: 0.08px;}
.filter-modal .fa-filter-modal-sidebar .sidebar-body .accordian-list .accordian-list-item span.MuiCheckbox-root{margin-right: -9px;}
.filter-modal .fa-filter-modal-sidebar .sidebar-body .accordian-list .accordian-list-item span.MuiCheckbox-root svg path{ fill: var(--marin-green); }
.filter-modal .fa-filter-modal-sidebar .sidebar-body .accordian-list .accordian-list-item span.MuiCheckbox-root input:disabled + svg path{fill: var(--light-gray)}
.filter-modal.buybox-modal .fa-filter-modal-sidebar .sidebar-body .accordian-list .accordian-list-item > svg:first-of-type{margin-right: 15px;}
.filter-modal .fa-filter-modal-sidebar .sidebar-bottom .apply-filter-btn{width: 100%; padding: 15px 20px; background-color: var(--marin-green); color: var(--white); font-family: var(--primary-font-family); font-size: 20px; font-weight: 600; line-height: 36px; letter-spacing: 0.12px; border-radius: 0;}
.filter-modal .filter-modal-inner .fa-filter-modal-main .modal-main-ctn{flex-grow: 1; padding: 12px 20px; height: auto; overflow: auto; }
.filter-modal .filter-modal-inner .fa-filter-modal-main .modal-main-bottom{padding: 11px 25px; justify-content: flex-end; background: #378d9a1a;}
.filter-modal .filter-modal-inner .fa-filter-modal-main .modal-main-bottom button{padding: 7px 20px; color: var(--marin-green); font-family: var(--primary-font-family); font-size: 16px; font-weight: 500; line-height: 28px; letter-spacing: 0.1px; border: 1px solid var(--marin-green); border-radius: 100px; margin-left: 18px;}
.filter-modal .filter-modal-inner .fa-filter-modal-main .modal-main-bottom button:hover{background-color: var(--marin-green); color: var(--white);}
.filter-modal .filter-modal-inner .fa-filter-modal-main .modal-main-top{justify-content: space-between; padding: 22px 30px; align-items: center;}
.filter-modal .filter-modal-inner .fa-filter-modal-main .modal-main-top h3{color: var(--dark-gray); font-size: 20px; font-weight: 600; line-height: 20px; letter-spacing: 0.1px;}
.filter-modal .fa-filter-modal-main .modal-main-top .filter-bar-search{margin-left: 18px; position: relative;}
.filter-modal .fa-filter-modal-main .modal-main-top .filter-bar-search input,
.fa-course-ctn .fa-course-ctn-search-wrap input{padding: 10px 18px 10px 50px; color: var(--light-gray); font-family: var(--primary-font-family); font-size: 16px; font-weight: 400; line-height: 24px; letter-spacing: -0.16px; border-radius: 10px;
    border: 1px solid var(--light-gray-2); background: #F9FBFF;}
.filter-modal .fa-filter-modal-main .modal-main-top .filter-bar-search .search-icon,
.fa-course-ctn .fa-course-ctn-search-wrap .search-icon{position: absolute; top: 12px; left: 18px;}
.filter-modal .fa-filter-modal-main .modal-main-top .expand-btn{ padding: 0; align-self: flex-start; width: 44px; height: 44px; justify-content: flex-end; min-width: 24px; padding: 10px; margin: -10px -10px 0 0;}
.filter-modal .fa-filter-modal-main .modal-main-ctn .modal-chip{flex-direction: row-reverse; height: 40px; background: var(--light-marin-green); padding: 10px 12px; border-radius: 100px; }
.filter-modal .fa-filter-modal-main .modal-main-ctn .inview_category .inview_category-chip-wrap{gap: 15px; flex-wrap: wrap;}
.filter-modal .fa-filter-modal-main .modal-main-ctn .modal-chip{ margin-left: 0; display: block;}
.filter-modal .fa-filter-modal-main .modal-main-ctn.app-filter .modal-chip{width: initial;}
.filter-modal .fa-filter-modal-main .modal-main-ctn .modal-main-ctn-inner.inview_category .modal-chip{width: fit-content; display: flex;
    align-items: center; flex-direction: row;}
.filter-modal-inner.fullScreen .fa-filter-modal-main .modal-main-ctn .modal-chip{width: auto;}
.filter-modal .fa-filter-modal-main .modal-main-ctn .modal-chip.selected{background-color: var(--marin-green);}
.filter-modal .fa-filter-modal-main .modal-main-ctn .modal-chip span{color: var(--marin-green); font-size: 16px; font-weight: 500; line-height: 20px; letter-spacing: 0.1px; padding: 0 6px 0 8px; font-family: var(--roboto-font);}
.filter-modal .fa-filter-modal-main .modal-main-ctn .modal-chip.selected span{color: var(--white); display: inline-block; width: fit-content;}
.filter-modal-inner.fullScreen .fa-filter-modal-main .modal-main-ctn .modal-chip.selected span{width: fit-content;}
.filter-modal .fa-filter-modal-main .modal-main-ctn .modal-chip svg{margin: 0;}
.filter-modal .fa-filter-modal-main .modal-main-ctn .modal-chip.selected svg{transform: rotate(45deg);}
.filter-modal .fa-filter-modal-main .modal-main-ctn .modal-chip.selected svg path{fill: white;}
.filter-modal .fa-filter-modal-main .modal-main-ctn .modal-main-ctn-inner{ padding: 24px 10px;}
.filter-modal .fa-filter-modal-main .modal-main-ctn .modal-main-ctn-inner + .modal-main-ctn-inner{border-top: 1px solid #378d9a80;}
.filter-modal .fa-filter-modal-main .modal-main-ctn .modal-main-ctn-inner .date-ctn-row{display: flex; gap: 30px;}
.filter-modal .fa-filter-modal-main .modal-main-ctn-inner .date-wrap {display: flex; gap: 10px; align-items: center;}
.filter-modal .fa-filter-modal-main .modal-main-ctn-inner .date-wrap h4{font-weight: 600; color: var(--marin-green);}
.filter-modal .fa-filter-modal-main .modal-main-ctn-inner .date-wrap span{font-size: 16px; border: 1px solid rgba(0, 0, 0, 0.23); padding: 0 12px; line-height: 50px; border-radius: 4px; vertical-align: middle; align-self: center; display: flex; align-items: center; gap: 5px;
    justify-content: space-between;}
.filter-modal .fa-filter-modal-main .modal-main-ctn-inner .date-wrap span svg{    fill: rgb(0 0 0 / 52%);}
.filter-modal .modal-main-ctn .modal-main-ctn-inner .filter-type-title{color: var(--dark-gray); font-size: 16px; font-weight: 600; line-height: 20px; letter-spacing: 0.1px; margin-bottom: 16px;}
.filter-modal .modal-main-ctn.app-filter .fa-chip-wrapper{gap: 15px; flex-wrap: wrap;}
.filter-modal .modal-main-ctn .modal-main-ctn-inner .range-wrap{display: flex; max-width: 700px; gap: 30px; align-items: center;}
.filter-modal .modal-main-ctn .modal-main-ctn-inner .range-wrap .slider-label-wrap{position: absolute; width: 100%; left: 0; bottom: 0px;}
.filter-modal .modal-main-ctn .modal-main-ctn-inner .range-wrap .slider-label{padding:0; margin:0; color: var(--primary-navy); font-size: 16px; font-weight: 400; line-height: 1;  letter-spacing: -0.16px;}
.filter-modal .modal-main-ctn .modal-main-ctn-inner .range-label{width: 114px; text-align: center; margin-bottom: 6px;}
.filter-modal .modal-main-ctn .modal-main-ctn-inner .range-label input.range-input{width: 100%; padding: 13px; text-align: center; color: var(--dark-gray); font-family: var(--primary-font-family); font-size: 20px; font-weight: 400; line-height: 24px; letter-spacing: 0.5px; border-radius: 8px; border: 1px solid #E8FCFF;}
/* Chrome, Safari, Edge, Opera */
.filter-modal .modal-main-ctn .modal-main-ctn-inner .range-label input.range-input::-webkit-outer-spin-button,
.filter-modal .modal-main-ctn .modal-main-ctn-inner .range-label input.range-input::-webkit-inner-spin-button {-webkit-appearance: none; margin: 0;}
/* Firefox */
.filter-modal .modal-main-ctn .modal-main-ctn-inner .range-label input.range-input[type=number] {-moz-appearance: textfield;}

.filter-modal .modal-main-ctn .modal-main-ctn-inner .range-label p{color: var(--primary-navy); font-size: 16px; font-weight: 400; line-height: 20px; letter-spacing: -0.16px;}
.filter-modal .modal-main-ctn .modal-main-ctn-inner .modal-slider-wrap{width: calc(100% - 290px); position: relative;}
.filter-modal .modal-main-ctn .modal-main-ctn-inner .modal-slider-wrap .modal-slider{padding: 24px 0;}
.filter-modal .modal-main-ctn .modal-main-ctn-inner .range-wrap .css-1diafny-MuiSlider-root{color: var(--marin-green);}
.filter-modal .modal-main-ctn .modal-main-ctn-inner .range-wrap  .css-frv3qg-MuiSlider-thumb:hover,.filter-modal .modal-main-ctn .modal-main-ctn-inner .range-wrap .css-frv3qg-MuiSlider-thumb.Mui-focusVisible{ box-shadow: 0px 0px 0px 8px rgba(55, 141, 154, 0.16); }
.main-content .filter-content {position: relative; display: flex; flex-direction: column; contain: inline-size; height: calc(100% - 82px); flex: 1 1 100%; justify-content: space-between;}
.filter-content .css-8atqhb .css-atdaqc-MuiPaper-root{ box-shadow: 0px 2px 1px -1px #EEEEEE,0px 1px 1px 0px #EEEEEE,0px 1px 3px 0px #EEEEEE; }
.filter-content .MuiStack-root{ align-items: center; }
.filter-content .MuiStack-root .MuiPagination-ul{gap: 10px;}
.filter-content .MuiStack-root .MuiPagination-ul li button svg{ width: 32px; height: 32px; }
.filter-content .MuiStack-root .MuiPagination-ul li button svg path{ fill: #526477; }
.filter-content .MuiStack-root .MuiPagination-ul li button{ color: #526477; font-family: var(--primary-font-family); font-size: 16px; font-style: normal; font-weight: 500; line-height: normal; letter-spacing: -0.16px; }
.filter-content .MuiStack-root .MuiPagination-ul li button.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected{ border-radius: 18px; background: #D6E1E6; }
.filter-modal .fa-filter-modal-sidebar .sidebar-body .fa-accordian-inner .MuiAccordionSummary-content svg{width: 24px; height: 24px; display: block; margin-right: 12px;}
.pagination-wrapper{ display: flex; flex-wrap: wrap; justify-content: center; align-items: center; gap: 20px; height: 70px; position: relative; width: 100%; border-top: 1px solid #eeeeee;}
.pagination-wrapper .per-page-row{position: absolute; right: 12px;}
.pagination-wrapper .per-page-row > div{border-radius: 10px; background-color: #F9FBFF;}
.pagination-wrapper .per-page-row > div fieldset{ border-color: var(--light-gray-2);}
.pagination-wrapper .per-page-row > div.Mui-focused fieldset{ border-color: var(--primary-navy) !important; border-width: 1px !important;}

/* Inner Filter Model */
/* Existing Lease End Date  Start*/
.fa-lease-date-wrap{padding: 22px 0 32px 0; width: 100%; height: 100%; max-width: 666px; margin: auto;}
.fa-lease-date-wrap .ld-left .to-wrap{margin-top: 35px;}
.fa-lease-date-wrap .ld-left p{ color: var(--primary-navy); font-family: var(--primary-font-family); font-size: 16px; font-weight: 400; line-height: 24px; letter-spacing: -0.16px;}
/* .fa-lease-date-wrap .css-11a8txn-MuiStack-root> .MuiFormControl-root{margin-top: 4px;} */
/* .fa-lease-date-wrap .ld-left .MuiFormControl-root input{padding-top: 14.5px; padding-bottom: 14.5px;} */
.fa-lease-date-wrap .ld-right .list-title-wrap{width: 357px; border-radius: 4px; border: 1px solid var(--light-gray); overflow-y: scroll; height: 100%; padding-left: 0;}
.fa-lease-date-wrap .ld-right ul{padding-left: 0;}
.fa-lease-date-wrap .ld-right ul{list-style: none; width: 100%;}
.fa-lease-date-wrap .ld-right ul li,
.fa-lease-date-wrap .ld-right ul li a{color: var(--primary-navy); font-size: 16px; font-weight: 400; line-height: 24px; letter-spacing: -0.16px; text-decoration: none;}
.fa-lease-date-wrap .ld-right .list-title-wrap .list-title{padding: 5px 10px; background:#ECECFE;}
.fa-lease-date-wrap .ld-right .list-title-wrap li{padding: 5px 22px; border-bottom: 1px solid var(--light-gray);}
.fa-lease-date-wrap .ld-right .list-title-wrap .sub-list-ul:last-child li:last-child{border: none;} 
/* .fa-lease-date-wrap .ld-left .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{border-color: var(--marin-green);} */
/* Calendar CSS */
/* .css-jgls56-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected){border-radius: 0px; border: 1px solid #378D9A;} */
/* .css-1u23akw-MuiButtonBase-root-MuiPickersDay-root.Mui-selected,
.css-jgls56-MuiButtonBase-root-MuiPickersDay-root.Mui-selected{ border-radius: 0px; border: 1px solid #015FCC; background: #378D9A !important; color: #fff;} */
/* .css-1u23akw-MuiButtonBase-root-MuiPickersDay-root:hover{border-radius: 0;} */
/* .css-1e6y48t-MuiButtonBase-root-MuiButton-root{color: #378D9A; font-family: var(--roboto-font); font-size: 12px; font-weight: 400;
line-height: 24px;} */
/* .MuiDialogActions-spacing{justify-content: flex-start; margin-top: -40px;} */
/* .MuiDateCalendar-root.css-1q04gal-MuiDateCalendar-root{width: 306px;} */
/* Existing Lease End Date Scrollbar */
.fa-lease-date-wrap .ld-right .list-title-wrap::-webkit-scrollbar{width: 5px; height: 0;}
.fa-lease-date-wrap .ld-right .list-title-wrap::-webkit-scrollbar-thumb{background: #D0D0D0; border-radius: 4px;}

/* Source Accordian Start */
.fa-course-ctn{display: flex; gap: 20px;}
.modal-main-ctn-inner.roll_category{height: 100%;}
.modal-main-ctn-inner.roll_category .fa-course-ctn{display: flex; gap: 20px; height: 100%;}
.fa-course-ctn .fa-course-ctn-left,
.fa-course-ctn .fa-course-ctn-right{width: 50%; padding-bottom: 20px;}
.fa-course-ctn .fa-course-ctn-right-inner{height: 100%; overflow: auto;}
.fa-course-ctn .nooption_selected{ padding: 8px 20px; border: 1px solid var(--marin-green); border-radius: 8px;
    background: var(--marin-green); color: #fff; height: 46px; font-size: 18px;}
.fa-course-ctn .fa-course-ctn-right .course-chip-wrap{display: flex; flex-wrap: wrap; gap: 10px;}
.fa-course-ctn .fa-course-ctn-search-wrap{position: relative; margin-bottom: 20px;}
.fa-course-ctn .fa-course-ctn-search-wrap input{width: 100%;}
.fa-course-ctn .fa-course-ctn-list-wrap {height: calc(100% - 66px); overflow: auto;}
.fa-course-ctn .fa-course-ctn-list-wrap ul{list-style: none; display: flex; flex-wrap: wrap; gap: 10px; padding: 0; margin: 0;}
.fa-course-ctn .fa-course-ctn-list-wrap ul::-webkit-scrollbar{width: 6px;}
.fa-course-ctn .fa-course-ctn-list-wrap ul::-webkit-scrollbar-thumb{background-color: red;}
.fa-course-ctn .fa-course-ctn-list-wrap ul li{padding: 8px 20px; border: 1px solid var(--marin-green); border-radius: 8px; width: 100%;}

.resizer{position: absolute; right: 0; top: 0; width: 2px; height: 100%; background-color: rgba(0, 0, 0, 0.5); cursor: col-resize; user-select: none; touch-action: none;}
.resizer.isResizing{ background-color: var(--marin-green);}

.react-loading-skeleton + br{display: none;}
.react-loading-skeleton:first-child{margin-top: 0 !important;}
.react-loading-skeleton:last-child{margin-bottom: 0 !important;}

.fa-loader{ position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-color: #378d9a69; display: flex; justify-content: center; align-items: center;}
.fa-loader div { height: 20px; width: 20px; border-radius: 50%; transform: scale(0); animation: animate 1.1s ease-in-out infinite; display: inline-block; margin: .5rem; background-color: #092C4C;}
.fa-loader div:nth-child(0) { animation-delay: 0s;}
.fa-loader div:nth-child(1) { animation-delay: 0.2s;}
.fa-loader div:nth-child(2) { animation-delay: 0.4s;}
.fa-loader div:nth-child(3) { animation-delay: 0.6s;}
.fa-loader div:nth-child(4) { animation-delay: 0.8s;}
.fa-loader div:nth-child(5) { animation-delay: 1s;}
@keyframes animate {
	0%, 100% {
		transform: scale(0.2);
	}
	40% {
		transform: scale(1);
	}
	50% {
		transform: scale(1);
	}
}
@keyframes loader_line {
    0%   {background:linear-gradient(to left, #ffd340 , #fff)}
    100%   {background:linear-gradient(to left, #ffd340 , #fff)}
  }

.no-act-filter-modal{max-width: 1100px; width: 90%; border-radius: 8px; background: #FFF; box-shadow: 0px 0px 27px 1px rgba(0, 0, 0, 0.15); top: 50%; position: absolute; left: 50%; transform: translate(-50%, -50%);}
.no-act-filter-inner{gap: 36px;  display: flex; align-items: center; padding: 50px 40px; position: relative;}
.no-act-filter-inner .no-filter-right h3{color: var(--primary-navy); font-size: 24px; font-weight: 700; line-height: 30px; margin-bottom: 15px;}
.no-act-filter-inner .no-filter-right p{color: var(--primary-navy); font-size: 16px; font-weight: 400; line-height: 30px;}
.no-act-filter-inner .close-btn{padding: 0; margin: 0; position: absolute; top: 18px; right: 18px; border: 2px solid transparent; width: 28px; height: 28px; background-color: transparent; cursor: pointer; border-radius: 4px;}
.no-act-filter-inner .close-btn:hover{box-shadow: 0 0 4px 0px #8a88888f;}
.characters {list-style: none; padding-left: 0;}
.characters li {display: flex; align-items: center; border: solid 2px #d0d0d0; border-radius: .2em; padding: .5em .8em .5em .5em; margin-bottom: 1em;}
.characters p {max-width: none;font-weight: bold;margin: 0;}
.characters-thumb {overflow: hidden;flex-shrink: 0;width: 2em;height: 2em;background-color: #e8e8e8;padding: .5em;margin-right: .5em;}
.characters-thumb img {display: block;width: 100%;height: auto;}
.fa-sidebar .fa-customise-view .customise-view-li-inner .view-error {order:1; color: #cd5656; width: 100%;}
.fa-sidebar .fa-customise-view .customise-view-li-inner .view-error p{margin: 0; font-size: 14px; line-height: 20px; position: relative; padding-left: 28px;}
.fa-sidebar .fa-customise-view .customise-view-li-inner .view-error p:before{content: ''; position: absolute; left: 10px; top: 50%; width: 14px; height: 14px; transform: translateY(-50%); background-image: url(../svg/error-red.svg); background-size: contain; background-position: center;}

/* Login Page Start */
.login-page{padding: 100px 0 50px; font-family: var(--primary-font-family);}
.login-page .login-inner{max-width: 510px; width: 100%; margin: auto;}
.login-page .login-inner .logo-wrap img{max-width: 480px; width: 100%; margin: auto; margin-bottom: 80px; display: block;}
.login-page .login-inner .form-wrap{padding: 70px 74px; border-radius: 24px; background: var(--white); border: 1px solid var(--light-gray-2); box-shadow: 0px 0px 25px 0px #E7E7E7;}
.login-page .login-inner .form-wrap .login-form-tabs .tabs{justify-content: center;}
.login-page .login-inner .login-form-tabs{border-radius: 24px; background: rgba(55, 141, 154, 0.10); max-width: 266px; margin: auto;}
.login-page .login-inner .login-form-tabs button{color: var(--marin-green); font-size: 16px; font-weight: 400; line-height: 24px; width: 120px;}
.login-page .login-inner .login-form-tabs button.Mui-selected{background-color: var(--marin-green); color: var(--white); flex-grow: 1; border-radius: 24px;}
.login-page .login-inner .tab-box{flex-grow: 1;}
.login-page .form{margin-top: 42px;}
.login-page .form .tagline{margin: 0 0 24px; color: var(--primary-navy); text-align: center; font-size: 18px; font-weight: 500; line-height: 26px;}
.login-page .form .form-field{margin-bottom: 24px;}
.login-page .form .form-field label{color: var(--dark-gray); font-size: 14px; font-weight: 400; line-height: normal; display: block;}
.login-page .form .form-field label span.MuiCheckbox-root{padding: 0; margin-right: 8px;}
.login-page .form .form-field input{padding: 9px 20px; display: block; max-width: 100%; width: 100%; border: 1px solid var(--dark-gray); border-radius: 4px;}
.login-page .rem-field .form-field input[type="checkbox"]{width: fit-content; margin: 0; width: 18px; height: 18px;}
.login-page .form .rem-field .remember-me{margin-left: 6px; font-weight: 300; line-height: 21px; height: 21px;}
.login-page .rem-field .forgot-pass{color: var(--primary-navy); font-size: 14px; font-weight: 300; line-height: 1; text-decoration: none;}
.login-page .form button[type="submit"]{padding: 16px; border-radius: 24px; text-align: center; background: var(--marin-green); width: 100%; font-size: 16px; font-weight: 400; line-height: 26px; color: var(--white); text-transform: capitalize;}
.login-page .form button[type="submit"]:disabled{background: var(--light-gray-2); color: var(--light-gray); }
.form-error{color: red; margin: 0;}
.login-page .form.register-form{margin: 0; height: 100%;}
.login-page .form.register-form .register-form-main{ display: flex; flex-direction: column; justify-content: space-between; height: 100%; padding-top: 50px;}
.login-page .register-form-main-inner .error-icon{max-width: 56px; height: 56px; margin: auto;}
.login-page .register-form-main-inner .error-icon svg{width: 100%; height: 100%; display: block;}
.login-page .form.register-form .register-form-main p{color: var(--light-gray); font-size: 16px; margin-top: 40px;}
/* Login Page End */

.main-content .table-wrap{padding: 0;}
.main-content .filter-search-result-row button{display: block; width: 100%; text-align: left; padding: 12px; border: 1px solid #F2F2F2; border-top-color: transparent;}
.main-content .filter-search-result-row:first-child button{border-top-color: #F2F2F2;}
.main-content .filter-search-result-row .drop-list{margin: 0; padding-left: 24px; position: relative; color: var(--marin-green); font-weight: 600; font-size: 14px;}
.main-content .filter-search-result-row .drop-list::before{content: ''; width: 20px; height: 20px; position: absolute; left: 0; top: 0; background-image: url(../svg/down.svg); background-size: 32px; background-position: center; background-repeat: no-repeat; transform: rotate(270deg);}
.main-content .filter-search-result-row .expanded.drop-list::before{transform: rotate(0);}

.overlay-scrollbar-hidden::-webkit-scrollbar {
    display: none;
}